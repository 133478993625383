<template>
  <div class="container">
    <el-tabs value="first" @tab-click="tabClick">
      <el-tab-pane label="未处理" name="first"></el-tab-pane>
      <el-tab-pane label="已处理" name="second"></el-tab-pane>
      <el-tab-pane label="无法解决" name="three"></el-tab-pane>
    </el-tabs>
    <div class="handle-box" style="float: left;">
      <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
    </div>
    <!--数据表格-->
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="正在加载 ..."
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50" />
      <el-table-column label="维修人员名称" prop="name" />
      <el-table-column label="联系方式" prop="telephone" />
      <el-table-column label="派单时间" prop="createTime" sortable />
      <el-table-column label="报修原因" prop="content" />
      <el-table-column label="无法处理的原因" prop="reason" />
      <el-table-column label="状态" prop="status" width="100" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0">未处理</el-tag>
          <el-tag v-if="scope.row.status === 1" type="success">已处理</el-tag>
          <el-tag v-if="scope.row.status === 2" type="info">无法解决</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="320" align="center">
        <template slot-scope="scope">
          <!-- <el-button
            type="primary"
            size="small"
            @click="exportMaintenance(scope.row.repairId)"
            >导出维修单</el-button
          > -->
          <el-button type="success" size="small" @click="onView(scope.row)"
            >查看详情</el-button
          >
          <el-dropdown>
            <el-button :disabled="scope.row.status === 1 ? true : false">
              操作
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="onStatus(scope.row, 1)"
                >已处理</el-dropdown-item
              >
              <el-dropdown-item @click.native="onStatus(scope.row, 2)"
                >无法解决</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pageInfo.startPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>

    <!--查看详情 -->
    <el-dialog title="查看详情" width="75%" :visible.sync="selectDlgShow">
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form label-position="left" :model="form" class="demo-table-expand">
          <el-form-item label="报修人名称:">
            <span>{{ form.name }}</span>
          </el-form-item>
          <el-form-item label="报修人手机号码:">
            <span>{{ form.telephone }}</span>
          </el-form-item>
          <el-form-item label="报修部门:">
            <span>{{ form.department }}</span>
          </el-form-item>
          <el-form-item label="报修日期:">
            <span>{{ form.repairDate }}</span>
          </el-form-item>
          <el-form-item label="完结日期:">
            <span>{{ form.finishDate }}</span>
          </el-form-item>
          <el-form-item label="报修地址:">
            <span>{{ form.address }}</span>
          </el-form-item>
          <el-form-item label="楼层:">
            <span>{{ form.floor }}</span>
          </el-form-item>
          <el-form-item label="房号:">
            <span>{{ form.roomno }}</span>
          </el-form-item>
          <el-form-item label="报修类型:">
            <el-tag v-if="form.type === 1">设备报修</el-tag>
            <el-tag v-if="form.type === 2">其他报修</el-tag>
          </el-form-item>
          <el-form-item label="报修原因:">
            <span>{{ form.reason }}</span>
          </el-form-item>
          <el-form-item label="好评度:">
            <el-rate
              v-model="form.commentStatus"
              style="float: left;margin-top: 10px;"
              disabled
              :colors="['#99A9BF', '#F7BA2A', '#FF9900']"
              :texts="['非常差', '差', '一般', '好', '非常好']"
              show-text
            ></el-rate>
          </el-form-item>
          <el-form-item label="评论内容:">
            <span>{{ form.commentContent }}</span>
          </el-form-item>
          <template v-if="form.imageList.length > 0">
            <el-row :gutter="20">
              <el-col v-for="item in form.imageList" :key="item.id" :span="8">
                <el-image :src="item.url"></el-image>
              </el-col>
            </el-row>
          </template>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="selectDlgShow = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { download } from '@/utils/request';
import { updateDispatch, getDispatchList, getRepairDetail } from '@/api/device';

export default {
  name: 'DeviceDispatch',
  data() {
    return {
      list: [],
      pageInfo: {
        startPage: 1,
        pageSize: 50,
        total: 0,
        userid: 0,
        status: 0
      },
      form: {
        name: '',
        telephone: '',
        department: '',
        repairDate: '',
        finishDate: '',
        address: '',
        floor: '',
        roomno: '',
        type: '',
        reason: '',
        commentStatus: 1,
        commentContent: '',
        imageList: []
      },
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      dialogVisible: false,
      multipleSelection: []
    };
  },
  created() {
    this.freshByTask();
    const roleCode = sessionStorage.getItem('roleCode');
    if (roleCode === 'admin') {
      this.pageInfo.userid = 0;
    } else {
      this.pageInfo.userid = sessionStorage.getItem('userId');
    }
  },
  methods: {
    async exportMaintenance(id) {
      await download({
        url: `${process.env.VUE_APP_HOST}/device/dispatch/exportRepairOrder?id=${id}`
      });
    },
    tabClick(tab) {
      let index = tab.index;
      let status = parseInt(index);
      this.pageInfo.status = status;
      this.onRefresh();
    },
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    search() {
      this.fetchData();
    },
    onRefresh() {
      this.fetchData();
    },
    //查询列表
    fetchData() {
      this.listLoading = true;
      getDispatchList(this.pageInfo).then(response => {
        this.list = response.data.records;
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
      });
    },
    //状态修改
    onStatus(row, status) {
      if (status === 2) {
        this.$prompt('请输入无法处理的原因', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
          .then(({ value }) => {
            this.updateStatus(row.id, status, value);
          })
          .catch(() => {
            console.log('cancel');
          });
      } else {
        this.$prompt('请输入耗材使用情况', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
          .then(({ value }) => {
            this.updateStatus(row.id, status, value);
          })
          .catch(() => {
            console.log('cancel');
          });

        // this.updateStatus(row.id, status);
      }
    },
    updateStatus(id, status, reason) {
      updateDispatch({ id: id, status: status, reason: reason }).then(resp => {
        if (resp.code == 200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.onRefresh();
        } else {
          this.$message.error('操作失败');
        }
      });
    },
    //查看详情
    onView(row) {
      getRepairDetail({ id: row.repairId }).then(resp => {
        if (resp.code === 200) {
          var data = resp.data;
          this.form = {
            name: data.name,
            telephone: data.telephone,
            department: data.department,
            repairDate: data.repairDate,
            finishDate: data.finishDate,
            address: data.address,
            floor: data.floor,
            roomno: data.roomno,
            type: data.type,
            reason: data.reason,
            commentStatus: data.commentStatus,
            commentContent: data.commentContent,
            imageList: data.imageList
          };
        }
      });
      this.selectDlgShow = true;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //定时更新任务
    freshByTask() {
      this.fetchData();
      setInterval(this.fetchData, 1000 * 15);
    }
  }
};
</script>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}

.el-form-item__label {
  font-weight: bold !important;
}
.el-dropdown {
  margin-left: 10px;
}
</style>
