import request from '@/utils/request';

/**
 * 查询列表
 * @param {Object} data
 */
export function getRepairList(data) {
  return request({
    url: '/device/repair/getRepairList',
    method: 'post',
    data
  });
}

/**
 * 删除操作
 * @param {Object} data
 */
export function delRepair(data) {
  return request({
    url: '/device/repair/delRepair',
    method: 'post',
    data
  });
}

/**
 * 工单状态修改
 * @param {Object} data
 */
export function updateRepair(data) {
  return request({
    url: '/device/repair/updateRepair',
    method: 'post',
    data
  });
}

/**
 * 查询派单详情，传入报修工单ID
 * @param {Object} data
 */
export function getDispatchDetail(data) {
  return request({
    url: '/device/repair/getDispatchDetail',
    method: 'post',
    data
  });
}

/**
 * 维修任务派单
 * @param {Object} data
 */
export function addDispatch(data) {
  return request({
    url: '/device/dispatch/addDispatch',
    method: 'post',
    data
  });
}

/**
 * 派单撤回
 * @param {Object} data
 */
export function deleteDispatch(data) {
  return request({
    url: '/device/dispatch/deleteDispatch',
    method: 'post',
    data
  });
}

/**
 * 派单状态修改
 * 传入派单任务ID，状态（0-未处理 1-已处理 2-无法解决）
 * @param {Object} data
 */
export function updateDispatch(data) {
  return request({
    url: '/device/dispatch/updateDispatch',
    method: 'post',
    data
  });
}

/**
 * 根据用户ID查询派单列表
 * @param {Object} data
 */
export function getDispatchList(data) {
  return request({
    url: '/device/dispatch/getDispatchList',
    method: 'post',
    data
  });
}

/**
 * 查看上报设备详情
 * @param {Object} data
 */
export function getRepairDetail(data) {
  return request({
    url: '/device/dispatch/getRepairDetail',
    method: 'post',
    data
  });
}
